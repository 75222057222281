.left {
  background-color: rgb(4, 24, 44);
}

.loginCard {
    padding: 20;
    margin: "auto";
    margin-top: '25%';
    margin-bottom: '4%';
    background-color: "rgb(230, 242, 255)";
}
.image {
  display: block;
  margin-top: 50%;
  height: 100px;
  margin-bottom: 50%;
}
.items {
  align-content: center;
  /* padding-top: 80px; */
}
p {
  color: rgb(15, 144, 213);
}
.text-danger {
  font-size: 1rem;
  text-transform: uppercase;
}

/* Use a media query to add a breakpoint at 800px: */
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
    .left {
        display: none;
    }
    .items{
        width: 90% !important;
        margin: 2% auto !important;
    }
    .loginCard {
        padding: 1rem !important;
        margin: auto !important;
        margin-top: 2% !important;
        margin-bottom: 2% !important;
        background-color: rgb(230, 242, 255) !important;
    };
}