/* // navbar.css */
.navbar {
  background-color: rgba(14, 46, 80);
  padding-right: 6rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
}
.navbar-collapse {
  /* flex-grow: 0 !important; */
}
.navigation {
  height: 20rem;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  overscroll-behavior: unset;
  padding: 0.5rem 0rem;
  background-color: rgba(14, 46, 77);
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}
.transparent-logo{
  /* width: 7rem; */
}
.name {
  margin: auto;
  color: rgb(255, 255, 255, 0.6);
}
.navigation-menu {
  margin-left: auto;
}
.navigation-menu ul {
  display: flex;
  padding: 0;
}
.navigation-menu li {
  /* // removes default disc bullet for li tags and applies margin to left & right side */
  list-style-type: none;
  margin: 0 1rem;
}
.navigation-menu li a {
  /* // increases the surface area of the anchor tag to span more than just the anchor text */
  text-decoration: none;
  display: block;
  width: 100%;
}
.hamburger {
  /* // removes default border on button element */
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #ffffff;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  /* // positions the icon to the right and center aligns it vertically */
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}
.ul {
  color: #eee;
}
.hamburger:hover {
  background-color: #ffffff;
}
@media screen and (max-width: 768px) {
  .navbar {
    background-color: rgba(14, 46, 80);
    padding-right: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
  }
  /* // rules go here */
  .hamburger {
    display: block;
  }
  .navigation-menu ul {
    display: none;
  }
  .navigation-menu ul {
    position: absolute;
    /* top: 60px; */
    right: 0;
    flex-direction: column;
    width: 25%;
    /* height: calc(100vh - 77px); */
    background-color: rgba(14, 46, 77);
    /* border-top: 1px solid black; */
  }
  .navigation-menu li {
    text-align: center;
    margin: 0;
  }
  .navigation-menu li a {
    color: black;
    /* // increases the surface area of the anchor tag to span the full width of the menu */
    width: 100%;
    padding: 1.5rem 0;
  }
  .navigation-menu li:hover {
    background-color: #eee;
  }
  .navigation-menu.expanded ul {
    display: block;
  }
  .fas{
    padding-right: 1px;
  }
}
