@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");
/* .App {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Nunito", sans-serif;
} */
.nextbtn {
  margin-bottom: 5%;
  margin-left: 40%;
  margin-right: 40%;
}

/* .data {
  flex: 3;
} */
.homepage {
  background-color: #e6f2ff;
  border-collapse: collapse;
  color: #000;
  font-family: arial, sans-serif;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 2%;
  /* margin-top: 30px; */
  width: 90%;
}
.form-outline {
  background-color: white;
}
.section {
  border: 1px solid #67ebff;
}
.question {
  border: 1px solid #67ebff;
  width: 50%;
}
/* .header {
  font-size: 15px;
  font-weight: 200;
  border: 0.05px solid #67ebff;
  font-family: Arial, Helvetica, sans-serif;
  height: max-content;
  text-transform: capitalize;
} */
.radio {
  padding-left: 50%;
}
td {
  text-align: center;
}
.gap-8 {
  margin-top: 30px;
}
.btn {
  padding: 10px;
  margin-left: 20px;
  font-size: 15px;
  font-weight: 500;
  width: fit-content;
}
.save {
  width: 4rem;
  margin-bottom: 0.5rem;
  align-items: end !important;
  display: "block" !important;
  justify-content: "flex-end";
}
.radio {
  padding-left: 6px;
  border: 1px solid #67ebff;
}
.implementation {
  text-align: center;
  width: max-content;
  color: rgb(0, 0, 0);
  border: 0.05px solid #67ebff;
  font-weight: bold;
  text-align: center;
}
.sidebar {
  display: flex !important;
  /* flex: 1 0!important; */
  flex-grow: 1 !important;
  background-color: rgb(188, 219, 253) !important;
}
.home_ {
  display: flex;
  flex-direction: row;
}
.left-homepage {
  /* width: 100%; */
  display: flex !important;
  flex-grow: 3 1 !important;
  justify-content: center !important;
}
.progress {
  margin-left: 5%;
  width: 90%;
  align-items: right !important;
  display: block;
}
.bar {
  margin-top: 20px;
}
.section_title {
  margin: 1% 5%;
  text-align: center;
  /* /* display: flex; */
  /* /* margin: auto; */
}
.span_sec {
  color: rgb(7, 3, 31);
  margin: auto;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 25px;
  /* color: blue; */
}
.circularProgressbar {
  font-size: 10px;
  color: white;
}
.progessBar {
  text-align: right !important;
  /* width: 90%; */
  width: 30px;
  justify-content: end;
}
#navbarRightAlign {
  flex-grow: 0 !important;
}

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 1200px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  .home_ .navbar-toggler {
    color: black;
    display: flex;
  }
  .home_ .sidebar {
    margin-top: 0rem;
    margin-left: 1rem;
    font-size: small;
    font-weight: 500;
    border-radius: 0.5rem;
  }
  .home_ .homepage {
    background-color: white !important;
  }
  .home_ {
    display: block;
    /* flex: none; */
  }
  .home_ .menu-btn {
    color: rgb(7, 28, 34);
    display: flex;
    flex: 1;
    margin-right: 1rem;
  }
  .home_ .gap-8 {
    display: flex;
    margin-top: 0.3rem;
    flex-direction: row;
  }
  .home_ .btn-primary {
    font-size: 0.5rem;
    padding: 0.3rem;
  }
  .home_ .pdf-btn {
    margin-left: 0.3rem !important;
    margin-right: 0.3rem;
    text-align: left;
    float: right;
    padding: 0.3rem;
    margin-bottom: 0.5rem;
  }
  .home_ .span_sec {
    font-size: large;
    font-weight: 500;
    text-align: left;
  }

  .home_ table {
    width: 100%;
    font-family: arial, sans-serif;
    color: rgb(0, 0, 0);
    margin: 0.3rem 0rem;
    /* height: fit-content; */
    /* margin-bottom: 1; */
  }
  /* Force table to not be like tables anymore */
  .home_ table,
  .home_ thead,
  .home_ tbody,
  .home_ th,
  .home_ td,
  .home_ tr {
    display: block;
  }
  .home_ tr {
    margin-bottom: 1rem  !important;
    padding: .5rem;
    margin-left: .5rem !important;
    margin-right: .5rem !important;
    background-color: rgb(188, 219, 253) !important;
    /* border: 0.005rem solid #3b3b3b !important; */
  }
  .home_ .radio {
    padding-left: 50%;
    height: auto;
    border: none;
    border-radius: 0.4rem;
  }
  .home_ .question {
    width: auto;
    border: none;
    font-size: small;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  .home_ thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .home_ tr {
    border: none;
    /* border-bottom: 0.5px solid #000000; */
  }

  .home_ td {
    /* Behave  like a "row" */
    /* border: none; */
    /* border: 0.5px solid #c7f8ff; */
    /* border-bottom: 1px solid rgb(0, 0, 0); */
    position: relative;
    padding-left: 50%;
    text-align: left;
  }

  .home_ td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    font-family: arial, sans-serif;
    width: 45%;
    padding-right: 10px;
    /* white-space: nowrap; */
  }
  /*
	Label the data
	*/

  @media (min-width: 768px) {
    .home_ .d-md-table-header-group {
      display: table-header-group !important;
    }
  }
  .home_ td:nth-of-type(1):before {
    font-weight: bold;
    font-size: small;
    height: max-content;
    font-family: arial, sans-serif;
    content: "Best Practice Definition";
  }

  .home_ td:nth-of-type(2):before {
    font-weight: bold;
    font-size: small;
    max-height: max-content;
    font-family: arial, sans-serif;
    content: "Fully Implemented";
  }

  .home_ td:nth-of-type(3):before {
    font-weight: bold;
    font-size: small;
    max-height: max-content;
    font-family: arial, sans-serif;
    content: "Partially Implemented";
  }

  .home_ td:nth-of-type(4):before {
    font-weight: bold;
    font-size: small;
    max-height: max-content;
    font-family: arial, sans-serif;
    content: "Not Implemented";
  }
}
