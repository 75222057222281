.sidebarstyle {
  text-align: left;
  position: sticky;
  background-color: rgb(215, 234, 255) !important;
  border-radius: 0px;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem !important;
}
.sidebarWrapper {
  padding: 5px;
  color: rgb(253, 253, 253);
}
.sidebarMenu {
  margin-bottom: 10px;
}
.sidebarTitle {
  font-size: 25px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px;
  border-radius: none;
  border-bottom: 0.1rem solid hsl(228, 100%, 5%);
  color: rgb(59, 154, 250);
}
.sidebarList {
  list-style: none;
  padding: 2%;
}
li {
  display: flex !important;
  text-align: left !important;
  text-align: justify;
}
.sidebarListItem {
  font-size: .9rem;
  text-transform: uppercase;
  padding: 0.9rem;
  color: black;
  font-weight: 520;
  display: block !important;
  text-align: left !important;
  cursor: pointer;
  width: 100%;
  border-radius: none;
  text-decoration: none;
}
.sidebarIcon {
  margin-right: 5px;
  font-size: 20px !important;
}

.active a {
  background-color: red;
  color: #fff;
}
.sidebarListItem:focus:focus,
.sidebarListItem:hover {
  background-color: rgba(99, 250, 255) !important;
  border-radius: 0.3rem;
  width: 100%;
}
/* .progress_bar{
  margin-right: -10%;
  margin-top: -9%;
} */

@media only screen and (max-width: 1200px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  li {
    display: block;
    text-align: left;
    font-size: calc(0.75em + 1vw);
  }
  .sidebarstyle {
    /* display: flex;
        flex: 1; */
    background-color: rgb(255, 255, 255, .5) !important;
    text-align: left;
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important;
    position: sticky;
  }
  .wrap-style{
   padding-left: 1rem !important;
  }
  .btn-primary {
    color: #fff !important;
    font-size: small !important;
    background-color: #0d6efd !important;
    border-color: none !important;
  }
  .sidebarListItem {
    font-size: .7rem;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0.5rem;
    color: black;
    display: block !important;
    text-align: left !important;
    cursor: pointer;
    width: 100%;
    border-radius: none;
    text-decoration: none;
  }
  .sidebarTitle {
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    padding: 0.5rem;
    border-radius: none;
    border-bottom: 0.02rem solid hsl(228, 100%, 5%);
    color: rgb(59, 154, 250);
  }
}

@media (min-width: 1280px) {
  li {
    display: block;
    text-align: left;
    font-size: calc(0.75em + 1vw);
  }
  .sidebarstyle {
    /* display: flex;
        flex: 1; */
    height: 100%;
    text-align: left;
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important;
    position: sticky;
  }
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
  li {
    display: block;
    text-align: left;
    font-size: calc(0.75em + 1vw);
  }
  .sidebarstyle {
    /* display: flex;
        flex: 1; */
    /* height: 100%; */
    text-align: left;
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important;
    position: sticky;
  }
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
  li {
    display: block;
    text-align: left;
    font-size: calc(0.75em + 1vw);
  }
  .sidebarstyle {
    /* display: flex; */
    /* flex: 1; */
    height: 100%;
    text-align: left;
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important;
    position: sticky;
  }
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  li {
    display: block;
    text-align: left;
    font-size: calc(0.75em + 1vw);
  }
  .sidebarstyle {
    /* display: flex; */
    /* flex: 1; */
    height: 100%;
    text-align: left;
    margin: 1.2rem 0rem;
    position: sticky;
  }
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
  /* CSS */
  li {
    display: block;
    text-align: left;
    font-size: calc(0.75em + 1vw);
  }
  .sidebarstyle {
    /* display: flex; */
    /* flex: 1; */
    height: 100%;
    text-align: left;
    margin: 1.2rem 0rem;
    position: sticky;
  }
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  li {
    display: block;
    text-align: left;
    font-size: calc(0.75em + 1vw);
  }
  .sidebarstyle {
    /* display: flex; */
    /* flex: 1; */
    height: 100%;
    text-align: left;
    margin: 1.2rem 0rem;
    position: sticky;
    top: 50px;
  }
}
