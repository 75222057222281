.left {
    background-color: rgb(4, 24, 44);
}

.row {
    display: flex;
    margin-top: 50px;
    margin-bottom: 50px;
}

.data-items {
    padding: 1rem;
    background-color: rgb(230, 242, 255);
    margin: "auto";
    background-color: "rgb(230, 242, 255)";
}

.thead1 {
    width: 10%;
    /* font-weight: bold; */
    text-align: center;
    text-transform: uppercase;
    color: rgb(0, 0, 0);
    border: 0.05px solid rgb(255, 255, 255);;
    font-weight: bold;
    color: #ffffff;
}

.thead2 {
    width: fit-content;
    color: rgb(0, 0, 0);
    border: 0.05px solid rgb(252, 253, 254);;
    /* font-weight: bold; */
    text-transform: uppercase;
    text-align: center;
    color: #ffffff;
}

.thead3 {
    color: rgb(0, 0, 0);
    border: 0.05px solid rgb(255, 255, 255);;
    /* font-weight: bold; */
    text-transform: uppercase;
    text-align: center;
    color: #ffffff;
}

.trow1 {
    height: 40px;
    background-color: rgb(4, 24, 44);
}
.tdata{
    text-align: left;
    padding-left: 5px;
    color: rgb(0, 0, 0);
    border: 0.05px solid #ffffff;
}
.display {
    width: 100%;
    background-color: rgb(230, 242, 255) !important;
    margin-bottom: 5%;
    font-family: arial, sans-serif;
    margin-top: 5%;
    overflow-x: auto !important;
    border-collapse: collapse;
}
td{
    border: 0.05px solid #ffffff;
}
.form-styles{
    background-color: rgb(230, 242, 255) !important;
    padding: 1rem;
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}
/* Use a media query to add a breakpoint at 800px: */
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
    .form-styles{
        width: 90%;
        margin: 1rem 1rem !important;
        padding: 1rem;
        font-size: small;
        background-color: rgb(230, 242, 255) !important;
    }
    .table-style{
        overflow: auto !important;
    }
    h2{
        font-size: medium !important;
    }
}


