.modalBackground{
  width: 100%;
  background-color: rgb(0, 0, 0, 0.16);
}
.modalContainer {
  display: flex !important;
  margin: 2rem auto!important; 
  width: fit-content;
  justify-content: center !important;
  align-items: center;
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  padding: 2px;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 5px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer .footer {
  flex: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 200px;
  font-size: 15px;
  height: 45px;
  margin: 10px;
  text-transform: uppercase;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
.modalbtn {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
#cancelBtn {
  background-color: crimson;
}
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  .modalContainer {
    width: 350px;
    height: 350px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 25px;
  }
  .modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }

  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }

  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }

  .modalContainer .body {
    flex: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-align: center;
  }

  .modalContainer .footer {
    flex: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modalContainer .footer button {
    width: 100px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
  }

  #cancelBtn {
    background-color: crimson;
  }
  .modal-styles{
    background-color: rgb(194, 223, 255) !important;
    margin: 2rem auto !important;
    border-radius: .4rem !important;
  }
}
